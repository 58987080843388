import * as React from "react";
import styles from "./CalendarAccountItem.module.css";
import {
  CalendarAccount,
  Calendar
} from "../../services/calendar/CalendarService";
import CalendarItem from "./CalendarItem";

interface IComponentState {
  calendars?: Calendar[];
  error?: Error;
}

interface IComponentProps {
  calendarAccount: CalendarAccount;
}

export default class CalendarAccountItem extends React.Component<
  IComponentProps,
  IComponentState
> {
  private _unsubscribeHandler?;

  constructor(props: IComponentProps) {
    super(props);

    this.state = {
      calendars: undefined,
      error: undefined
    };

    this._unsubscribeHandler = window.CalendarService.registerHandlerGetCalendars(
      (payload, error) => {
        this.setState(state => ({
          ...state,
          isLoading: false,
          calendars: payload && payload.calendars,
          error
        }));
      }
    );
  }

  public componentDidMount() {
    window.CalendarService.raiseEventGetCalendars(
      this.props.calendarAccount.Id
    );
  }

  public componentWillUnmount() {
    if (this._unsubscribeHandler) {
      this._unsubscribeHandler();
    }
  }

  public render() {
    return (
      <div className={styles["host"]}>
        <div className={styles["header"]}>
          <h1>{this.props.calendarAccount.AccountName}</h1>
          <button
            className={styles["remove-button"]}
            onClick={() =>
              window.CalendarService.removeCalendarAccount(
                this.props.calendarAccount.Id
              )
            }
          >
            Remove Account
          </button>
        </div>

        {this.state.calendars !== undefined && (
          <div className={styles["calendar-list"]}>
            {this.state.calendars!.map((c, index, _) => {
              return (
                <div key={`c-${this.props.calendarAccount.Id}-${index}`}>
                  <CalendarItem calendar={c} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}
