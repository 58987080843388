import * as React from "react";
import styles from "./CalendarItem.module.css";
import { Calendar } from "../../services/calendar/CalendarService";
import Switch from "@material-ui/core/Switch";

interface IComponentState {}

interface IComponentProps {
  calendar: Calendar;
}

export default class CalendarItem extends React.PureComponent<
  IComponentProps,
  IComponentState
> {
  public render() {
    return (
      <div className={styles["host"]}>
        <div className={styles["inline"]}>
          <div
            className={styles["circle"]}
            style={{ background: this.props.calendar.Color }}
          />
          <div>{this.props.calendar.Title}</div>
        </div>
        <Switch
          onChange={this.handleCalendarToggle}
          checked={!this.props.calendar.IsDisabled}
        />
      </div>
    );
  }

  private handleCalendarToggle = event => {
    if (event.target.checked) {
      window.CalendarService.enableCalendar(this.props.calendar);
    } else {
      window.CalendarService.disableCalendar(this.props.calendar);
    }
  };
}
