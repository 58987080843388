import { addDays, addMinutes } from "date-fns";

export enum QueryStringNames {
  DISPLAYED_DATE = "display"
}

// all of these constants must match on the native side
export enum InteropConstants {
  NATIVE_EVENT_EVENTNAME = "eventFromNative",
  NATIVE_EVENT_WITH_DATA_EVENTNAME = "eventFromNativeWithData",
  NATIVE_EVENT_POPOVERWILLSHOW_DETAILNAME = "popoverWillShow",
  NATIVE_EVENT_POPOVERWILLCLOSE_DETAILNAME = "popoverWillClose",
  NATIVE_EVENT_MONTHAPPOINTMENTS_DETAILNAME = "monthAppointments",
  NATIVE_EVENT_CALENDAR_PERMISSION_STATUS = "calendarPermissionStatus",
  NATIVE_EVENT_CALENDARS_UPDATED = "calendarsUpdated",
  JS_MESSAGE_LOG = "log",
  JS_MESSAGE_GETMONTHAPPOINTMENTS = "getMonthAppointments",
  JS_MESSAGE_OPENAPPOINTMENT = "openAppointment",
  JS_MESSAGE_OPENDATE = "openDate",
  JS_MESSAGE_GET_CALENDAR_PERMISSION_STATUS = "getCalendarPermissionStatus",
  JS_MESSAGE_REQUEST_CALENDAR_PERMISSION = "requestCalendarPermission",
  JS_MESSAGE_DISABLE_CALENDAR = "disableCalendar",
  MESSAGE_DELIMITER = "::"
}

export function rand(minInclusive: number, maxInclusive: number): number {
  const min = Math.ceil(minInclusive);
  const max = Math.floor(maxInclusive);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Generates a GUID
 *
 * @export
 * @returns {string}
 */
export function guid(): string {
  // influenced from
  // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c => {
    const asNumber = Number(c);
    return (
      asNumber ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (asNumber / 4)))
    ).toString(16);
  });
}

/**
 * Logs a message
 *
 * @export
 * @param {string} message
 */
export function log(message: string) {
  // tslint:disable-next-line:no-console
  console.log(message);

  if (getIsInMacWebview()) {
    (window as any).webkit.messageHandlers.scriptHandler.postMessage(
      `${InteropConstants.JS_MESSAGE_LOG}${
        InteropConstants.MESSAGE_DELIMITER
      }${message}`
    );
  }
}

/**
 * Logs an error
 *
 * @export
 * @param {string} message
 */
export function logError(message: string) {
  // tslint:disable-next-line:no-console
  console.error(message);

  if (getIsInMacWebview()) {
    (window as any).webkit.messageHandlers.scriptHandler.postMessage(
      `${InteropConstants.JS_MESSAGE_LOG}${
        InteropConstants.MESSAGE_DELIMITER
      }-ERROR-${message}`
    );
  }
}

/**
 * Returns true if running in Standalone/App mode, in other words, launched from
 * the homescreen like a native app
 *
 * @export
 * @returns {boolean}
 */
export function isStandaloneApp(): boolean {
  return (
    "standalone" in window.navigator && (window.navigator as any).standalone
  );
}

/**
 * Returns true if running on iOS in Safari browser (and not in Standlone/App mode)
 *
 * @export
 * @returns {boolean}
 */
export function isSafariMobile(): boolean {
  const ua = window.navigator.userAgent;
  const iOS =
    !!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/iPod/i);
  const webkit = !!ua.match(/WebKit/i);

  // Chrome browser on iOS has similar UA but it adds some CriOS part so just look for that
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

  return iOSSafari && !isStandaloneApp();
}

export function getDaysInMonth(year, month): Date[] {
  const result: Date[] = [];

  // get first day of month
  const date = new Date(year, month - 1, 1);

  // determine day of week it falls on
  let prevMonthDays = date.getDay();

  // fill in days of previous month
  while (prevMonthDays > 0) {
    result.push(addDays(date, -1 * prevMonthDays));
    prevMonthDays -= 1;
  }

  // fill in days of current month
  while (date.getMonth() === month - 1) {
    result.push(new Date(date.valueOf()));
    date.setDate(date.getDate() + 1);
  }

  // fill in days of next month
  while (date.getDay() !== 0) {
    result.push(new Date(date.valueOf()));
    date.setDate(date.getDate() + 1);
  }

  return result;
}

export function convertUtcDateToLocalDate(date: Date): Date {
  // When it comes to UI and user input we want to deal with local time.
  // Creating a new Date object uses UTC, so this method allows us to convert a
  // Date object that was created based on UTC time to instead be based on
  // local time.  NOTE: this method should only be used when dealing with
  // user-entered dates (like from the URL bar or inputs).
  return addMinutes(date, date.getTimezoneOffset());
}

export function toYearMonthDayString(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  // seems like safari can't parse a date if the month and day isn't in 2 digit
  // format
  const twoDigitMonth = month > 9 ? `${month}` : `0${month}`;
  const twoDigitDay = day > 9 ? `${day}` : `0${day}`;
  return `${year}-${twoDigitMonth}-${twoDigitDay}`;
}

export function toYearMonthString(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  // seems like safari can't parse a date if the month isn't in 2 digit format
  const twoDigitMonth = month > 9 ? `${month}` : `0${month}`;
  return `${year}-${twoDigitMonth}`;
}

const isInMacWebview = navigator.userAgent === "macoswebview";
export function getIsInMacWebview(): boolean {
  return isInMacWebview;
}

const isIniOSWebview = navigator.userAgent === "ioswebview";
export function getIsIniOSWebview(): boolean {
  return isIniOSWebview;
}

const isInAndroidWebview = navigator.userAgent === "androidwebview";
export function getIsInAndroidWebview(): boolean {
  return isInAndroidWebview;
}

const isInWindowsWebview = navigator.userAgent === "windowswebview";
export function getIsInWindowsWebview(): boolean {
  return isInWindowsWebview;
}

export function getIsPWA(): boolean {
  return (
    !isInMacWebview &&
    !isIniOSWebview &&
    !isInAndroidWebview &&
    !isInWindowsWebview
  );
}

export function readDateFromURL(
  params: URLSearchParams,
  paramName: string
): Date {
  if (!!params) {
    const paramDate = params.get(paramName);
    if (!!paramDate) {
      if (paramDate === "now") {
        return new Date();
      }

      const parsedDate = new Date(paramDate);
      if (!!parsedDate) {
        // since the URL is user-entered and therefore local time
        // we need to convert the Date object which was created with
        // UTC time into local time
        return convertUtcDateToLocalDate(parsedDate);
      }
    }
  }

  return new Date();
}

/**
 * Given 2 Map objects that use string values as keys, determines if the keys
 * are the same for both Map objects
 *
 * @export
 * @template T
 * @param {Map<string, T>} mapA
 * @param {Map<string, T>} mapB
 * @returns
 */
export function areMapKeysEqual<T>(mapA: Map<string, T>, mapB: Map<string, T>) {
  if (mapA.size !== mapB.size) {
    return false;
  }

  for (const key in mapA.keys) {
    if (!mapB.has(key)) {
      return false;
    }
  }

  return true;
}

let _midnightTimeout: NodeJS.Timeout | null = null;
export function setMidnightInterval(funcToRun: Function) {
  const now = new Date();
  const midnight = addDays(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0),
    1
  );
  var msToMidnight = midnight.getTime() - now.getTime();

  _midnightTimeout = setTimeout(() => {
    funcToRun();
    setMidnightInterval(funcToRun);
  }, msToMidnight);
}

export function stopMidnightIntervalTimer() {
  if (_midnightTimeout) {
    clearTimeout(_midnightTimeout);
    _midnightTimeout = null;
  }
}
