import {
  isWithinRange,
  isSameMonth,
  isSameDay,
  addMonths,
  addDays,
  differenceInMinutes
} from "date-fns";
import * as React from "react";
import { HotKeys } from "react-hotkeys";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AlertsContext, IAlertsContext } from "../contexts/alerts-context";
import {
  CalendarsContext,
  ICalendarsContext
} from "../contexts/calendars-context";
import {
  ISelectedDateContext,
  SelectedDateContext
} from "../contexts/selectedDate-context";
import { PermissionStatus } from "../models/PermissionStatus";
import { ThemeColors } from "../styles";
import {
  getIsInMacWebview,
  InteropConstants,
  log,
  logError,
  QueryStringNames,
  readDateFromURL,
  toYearMonthString,
  setMidnightInterval,
  stopMidnightIntervalTimer
} from "../utils";
import AlertBannerContainer from "./Alerts/AlertBannerContainer";
import AppointmentList from "./AppointmentList/AppointmentList";
import LeftDrawer from "./LeftDrawer";
import { MonthDayOfWeekRow } from "./MonthDayOfWeekRow";
import MonthFooter from "./MonthFooter";
import MonthGrid from "./MonthGrid";
import MonthHeader from "./MonthHeader";
import "infinite-carousel-wc";
import "side-drawer";
import { InfiniteCarouselWc } from "infinite-carousel-wc";
import { SideDrawer } from "side-drawer";
import { GetAppointmentsPayload } from "../services/calendar/CalendarService";
import { yearMonthToString } from "../services/calendar/CalendarUtils";

interface IComponentState {
  isDrawerOpen: boolean;
  inNoAppointmentsMode: boolean;
  selectedDateContext: ISelectedDateContext;
  alertsContext: IAlertsContext;
  calendarsContext: ICalendarsContext;
  calendarPermissionStatus?: PermissionStatus;
  currentSlot: 1 | 2 | 3;
  slot1Date: Date;
  slot2Date: Date;
  slot3Date: Date;
  slot1Payload?: GetAppointmentsPayload;
  slot2Payload?: GetAppointmentsPayload;
  slot3Payload?: GetAppointmentsPayload;
  selectedDatePayload?: GetAppointmentsPayload;
}

interface IComponentProps {}

type ICombinedProps = IComponentProps & RouteComponentProps<{}>;

class MonthCalendar extends React.Component<ICombinedProps, IComponentState> {
  private _popoverClosedTime?: Date;
  private _carouselRef: React.RefObject<InfiniteCarouselWc>;
  private _sideDrawerRef: React.RefObject<SideDrawer>;

  private keyMap = {
    goLeft: "left",
    goRight: "right",
    goUp: "up",
    goDown: "down",
    today: "t",
    home: "home",
    end: "end"
  };

  private handlers = {
    goLeft: event => this.handleSelectedDayChanged(-1),
    goRight: event => this.handleSelectedDayChanged(1),
    goUp: event => this.handleSelectedDayChanged(-7),
    goDown: event => this.handleSelectedDayChanged(7),
    today: event => this.goToToday(),
    end: event => this.handleNextMonthClicked(),
    home: event => this.handlePreviousMonthClicked()
  };

  private noAppointmentModeHandlers = {
    goLeft: event => this.handlePreviousMonthClicked(),
    goRight: event => this.handleNextMonthClicked(),
    today: event => this.goToToday(),
    end: event => this.handleNextMonthClicked(),
    home: event => this.handlePreviousMonthClicked()
  };

  private _unsubscribeGetCalendarAccountsHandler?: () => void;
  private _unsubscribeGetAppointmentsHandler?: () => void;

  constructor(props: ICombinedProps) {
    super(props);
    this._carouselRef = React.createRef();
    this._sideDrawerRef = React.createRef();

    const urlDate = readDateFromURL(
      new URLSearchParams(location.search),
      QueryStringNames.DISPLAYED_DATE
    );

    this.state = {
      inNoAppointmentsMode: true,
      isDrawerOpen: false,
      selectedDateContext: {
        today: new Date(),
        selectedDate: new Date(),
        selectDate: this.handleSelectDate
      },
      alertsContext: {
        enableShowAppointmentsAlert: false,
        showAppointmentsAlertResult: this.handleShowAppointmentsAlertResult,
        enableShowAppointmentsDrawerAlert: false,
        showAppointmentsDrawerAlertResult: this
          .handleShowAppointmentsDrawerAlertResult,
        enableNoCalendarsDrawerAlert: false
      },
      calendarsContext: {
        calendars: [],
        refreshCalendars: this.fetchCurrentMonthAppointments
      },
      calendarPermissionStatus: undefined,
      slot1Date: urlDate,
      slot2Date: addMonths(urlDate, 1),
      slot3Date: addMonths(urlDate, -1),
      currentSlot: 1
    };

    // make sure to update the current day at midnight
    setMidnightInterval(() => {
      log(
        `midnight timer hit at ${new Date()}, updating this.state.selectedDateContext.today`
      );
      this.setState(state => ({
        ...state,
        selectedDateContext: { ...state.selectedDateContext, today: new Date() }
      }));
    });

    this._unsubscribeGetCalendarAccountsHandler = window.CalendarService.registerHandlerGetCalendarAccounts(
      (payload, error) => {
        if (payload) {
          const noAccounts = payload.calendarAccounts.length === 0;
          this.setState(state => ({
            ...state,
            inNoAppointmentsMode: noAccounts,
            alertsContext: {
              ...state.alertsContext,
              enableNoCalendarsDrawerAlert: noAccounts
            }
          }));

          // TODO - if the account needs to be auth-ed then we need to show an
          // alert somewhere
        } else {
          // TODO - show error UI or something
          console.error(`Got error back from GetCalendarAccounts: ${error}`);
        }
      }
    );

    this._unsubscribeGetAppointmentsHandler = window.CalendarService.registerHandlerGetAppointments(
      (payload, error) => {
        if (payload) {
          // only care about payloads that have year and month
          if (payload.year !== undefined && payload.month !== undefined) {
            const yearMonth = yearMonthToString(payload.year, payload.month);

            let changed = false;
            let slot1: GetAppointmentsPayload | undefined,
              slot2: GetAppointmentsPayload | undefined,
              slot3: GetAppointmentsPayload | undefined,
              selected: GetAppointmentsPayload | undefined;

            if (yearMonth === toYearMonthString(this.state.slot1Date)) {
              slot1 = payload;
              changed = true;
            }

            if (yearMonth === toYearMonthString(this.state.slot2Date)) {
              slot2 = payload;
              changed = true;
            }

            if (yearMonth === toYearMonthString(this.state.slot3Date)) {
              slot3 = payload;
              changed = true;
            }

            if (
              yearMonth ===
              toYearMonthString(this.state.selectedDateContext.selectedDate)
            ) {
              selected = payload;
              changed = true;
            }

            if (changed) {
              this.setState(state => ({
                ...state,
                slot1Payload: slot1 || state.slot1Payload,
                slot2Payload: slot2 || state.slot2Payload,
                slot3Payload: slot3 || state.slot3Payload,
                selectedDatePayload: selected || state.selectedDatePayload
              }));
            }
          } else {
            log("GetAppointments payload did not have month and year");
          }
        } else {
          // TODO - show error UI or something
          console.error(`Got error back from GetAppointments: ${error}`);
        }
      }
    );

    if (getIsInMacWebview()) {
      window.addEventListener(
        InteropConstants.NATIVE_EVENT_EVENTNAME,
        this.handleEventFromNative
      );
    }
  }

  public componentDidUpdate(
    prevProps: ICombinedProps,
    prevState: IComponentState
  ) {
    const currentDate = ((this.state.currentSlot === 1 &&
      this.state.slot1Date) ||
      (this.state.currentSlot === 2 && this.state.slot2Date) ||
      (this.state.currentSlot === 3 && this.state.slot3Date)) as Date;
    const prevDate = ((prevState.currentSlot === 1 && prevState.slot1Date) ||
      (prevState.currentSlot === 2 && prevState.slot2Date) ||
      (prevState.currentSlot === 3 && prevState.slot3Date)) as Date;
    if (!isSameMonth(currentDate, prevDate)) {
      // kick off appointment retrieval
      window.CalendarService.raiseEventGetAppointmentsForMonth(
        currentDate.getFullYear(),
        currentDate.getMonth()
      );
      this.primeSurroundingMonths(currentDate);

      const params = new URLSearchParams(location.search);
      params.set(
        QueryStringNames.DISPLAYED_DATE,
        toYearMonthString(currentDate)
      );
      window.history.replaceState({}, "", `${location.pathname}?${params}`);
    }
  }

  public async componentDidMount() {
    window.CalendarService.raiseEventGetCalendarAccounts();
    const displayDate = this.getDisplayDate();
    window.CalendarService.raiseEventGetAppointmentsForMonth(
      displayDate.getFullYear(),
      displayDate.getMonth()
    );
    this.primeSurroundingMonths(displayDate);

    if (this._carouselRef.current) {
      this._carouselRef.current.addEventListener(
        "next",
        this.handleCarouselNext
      );
      this._carouselRef.current.addEventListener(
        "previous",
        this.handleCarouselPrevious
      );
    }

    if (this._sideDrawerRef.current) {
      this._sideDrawerRef.current.addEventListener(
        "open",
        this.handleDrawerOpened
      );
      this._sideDrawerRef.current.addEventListener(
        "close",
        this.handleDrawerClosed
      );
    }
  }

  public componentWillUnmount() {
    stopMidnightIntervalTimer();

    if (this._unsubscribeGetAppointmentsHandler) {
      this._unsubscribeGetAppointmentsHandler();
    }

    if (this._unsubscribeGetCalendarAccountsHandler) {
      this._unsubscribeGetCalendarAccountsHandler();
    }

    if (getIsInMacWebview()) {
      window.removeEventListener(
        InteropConstants.NATIVE_EVENT_EVENTNAME,
        this.handleEventFromNative
      );
    }

    if (this._carouselRef.current) {
      this._carouselRef.current.removeEventListener(
        "next",
        this.handleCarouselNext
      );
      this._carouselRef.current.removeEventListener(
        "previous",
        this.handleCarouselPrevious
      );
    }

    if (this._sideDrawerRef.current) {
      this._sideDrawerRef.current.removeEventListener(
        "open",
        this.handleDrawerOpened
      );
      this._sideDrawerRef.current.removeEventListener(
        "close",
        this.handleDrawerClosed
      );
    }
  }

  public render() {
    const date = this.getDisplayDate();

    const slot1Appointments =
      this.state.slot1Payload && this.state.slot1Payload.appointments;
    const slot2Appointments =
      this.state.slot2Payload && this.state.slot2Payload.appointments;
    const slot3Appointments =
      this.state.slot3Payload && this.state.slot3Payload.appointments;
    const selectedDateAppointments =
      this.state.selectedDatePayload &&
      this.state.selectedDatePayload.appointments.filter(a => {
        const end = a.StartTime === a.EndTime ? a.EndTime : a.EndTime - 1;
        const start = a.StartTime;

        if (
          isSameDay(this.state.selectedDateContext.selectedDate, start) ||
          isSameDay(this.state.selectedDateContext.selectedDate, end)
        ) {
          return true;
        }

        if (end < start) {
          logError("EndDate is before StartDate");
          // I guess include the appointment anyway
          return isWithinRange(
            this.state.selectedDateContext.selectedDate,
            end,
            start
          );
        }

        return isWithinRange(
          this.state.selectedDateContext.selectedDate,
          start,
          end
        );
      });

    const noAppointments = this.state.inNoAppointmentsMode;

    return (
      <HotKeys
        keyMap={this.keyMap}
        handlers={
          noAppointments ? this.noAppointmentModeHandlers : this.handlers
        }
        style={{ height: "100%" }}
        attach={window}
        focused
      >
        <div className="month-calendar-container">
          <div className="month-calendar-row-header">
            <MonthHeader
              year={date.getFullYear()}
              month={date.getMonth()}
              onDateClick={this.goToToday}
              onNextClick={this.handleNextMonthClicked}
              onPreviousClick={this.handlePreviousMonthClicked}
            />
          </div>
          <div className="month-calendar-row-week-names">
            <MonthDayOfWeekRow />
          </div>
          <div className="month-calendar-row-month-grid">
            <SelectedDateContext.Provider
              value={this.state.selectedDateContext}
            >
              <infinite-carousel-wc
                style={{ height: "100%" }}
                ref={this._carouselRef}
              >
                <div slot="1">
                  <MonthGrid
                    monthDate={this.state.slot1Date}
                    appointments={slot1Appointments}
                    inNoAppointmentsMode={noAppointments}
                  />
                </div>
                <div slot="2">
                  <MonthGrid
                    monthDate={this.state.slot2Date}
                    appointments={slot2Appointments}
                    inNoAppointmentsMode={noAppointments}
                  />
                </div>
                <div slot="3">
                  <MonthGrid
                    monthDate={this.state.slot3Date}
                    appointments={slot3Appointments}
                    inNoAppointmentsMode={noAppointments}
                  />
                </div>
              </infinite-carousel-wc>
            </SelectedDateContext.Provider>
          </div>
          {noAppointments && (
            <div
              style={{
                height: "1px",
                backgroundColor: ThemeColors.SecondaryBackground
              }}
            />
          )}
          {!noAppointments && (
            <div className="month-calendar-row-appointment-list">
              <CalendarsContext.Provider value={this.state.calendarsContext}>
                <AppointmentList
                  date={this.state.selectedDateContext.selectedDate}
                  appointments={selectedDateAppointments}
                />
              </CalendarsContext.Provider>
            </div>
          )}
          <div className="month-calendar-row-alert-banner">
            <AlertsContext.Provider value={this.state.alertsContext}>
              <AlertBannerContainer />
            </AlertsContext.Provider>
          </div>
          <div className="month-calendar-row-footer">
            <MonthFooter
              onMenuClick={this.handleMenuClick}
              onTodayClick={this.goToToday}
            />
          </div>
          <side-drawer
            open={this.state.isDrawerOpen || undefined}
            ref={this._sideDrawerRef}
            style={{ width: "unset" }}
          >
            <CalendarsContext.Provider value={this.state.calendarsContext}>
              <AlertsContext.Provider value={this.state.alertsContext}>
                <LeftDrawer onClose={this.handleDrawerClosed} />
              </AlertsContext.Provider>
            </CalendarsContext.Provider>
          </side-drawer>
        </div>
      </HotKeys>
    );
  }

  private handleDrawerOpened = () => {
    this.setState(state => ({ ...state, isDrawerOpen: true }));
  };

  private handleDrawerClosed = () => {
    this.setState(state => ({ ...state, isDrawerOpen: false }));
  };

  private handleCarouselNext = evt => {
    return this.setState(state => {
      switch (evt.detail.newCurrent) {
        case 1:
          return {
            ...state,
            slot2Date: addMonths(state.slot1Date, 1),
            currentSlot: 1
          };
        case 2:
          return {
            ...state,
            slot3Date: addMonths(state.slot2Date, 1),
            currentSlot: 2
          };
        case 3:
          return {
            ...state,
            slot1Date: addMonths(state.slot3Date, 1),
            currentSlot: 3
          };
      }
      return null;
    });
  };

  private handleCarouselPrevious = evt => {
    return this.setState(state => {
      switch (evt.detail.newCurrent) {
        case 1:
          return {
            ...state,
            slot3Date: addMonths(state.slot1Date, -1),
            currentSlot: 1
          };
        case 2:
          return {
            ...state,
            slot1Date: addMonths(state.slot2Date, -1),
            currentSlot: 2
          };
        case 3:
          return {
            ...state,
            slot2Date: addMonths(state.slot3Date, -1),
            currentSlot: 3
          };
      }
      return null;
    });
  };

  private handleSelectDate = (date: Date) => {
    if (!isSameDay(this.state.selectedDateContext.selectedDate, date)) {
      this.setState(state => ({
        ...state,
        selectedDateContext: {
          ...state.selectedDateContext,
          selectedDate: date
        }
      }));
    }
  };

  private handleShowAppointmentsAlertResult = (showAppointments: boolean) => {
    if (showAppointments) {
      // this.requestCalendarAccess();
    }

    this.setState(state => ({
      ...state,
      alertsContext: {
        ...state.alertsContext,
        enableShowAppointmentsAlert: false
      }
    }));
  };

  private handleShowAppointmentsDrawerAlertResult = (
    showAppointments: boolean
  ) => {
    if (showAppointments) {
      // this.requestCalendarAccess();
    }
  };

  private handleMenuClick = () => {
    this.setState({ ...this.state, isDrawerOpen: true });
  };

  private goToToday = () => {
    this.setDisplayDate(new Date());
    this.handleSelectDate(new Date());
  };

  private handlePreviousMonthClicked = () => {
    this._carouselRef.current!.goPrevious();
  };

  private handleNextMonthClicked = () => {
    this._carouselRef.current!.goNext();
  };

  private handleSelectedDayChanged = (dayChange: number) => {
    if (dayChange !== 0) {
      const newSelectedDate = addDays(
        this.state.selectedDateContext.selectedDate,
        dayChange
      );

      this.setState(state => ({
        ...state,
        selectedDateContext: {
          ...state.selectedDateContext,
          selectedDate: newSelectedDate
        }
      }));
      if (!isSameMonth(this.getDisplayDate(), newSelectedDate)) {
        this.setDisplayDate(newSelectedDate);
      }
    }
  };

  private getDisplayDate = (): Date => {
    return ((this.state.currentSlot === 1 && this.state.slot1Date) ||
      (this.state.currentSlot === 2 && this.state.slot2Date) ||
      (this.state.currentSlot === 3 && this.state.slot3Date)) as Date;
  };

  private setDisplayDate = (date: Date) => {
    this.setState(state => {
      return {
        ...state,
        slot1Date: date,
        slot2Date: addMonths(date, 1),
        slot3Date: addMonths(date, -1),
        currentSlot: 1
      };
    });
    this._carouselRef.current!.reset();
  };

  // TODO - move this into some sort of native service plugin
  private handleEventFromNative = event => {
    log(`handleEventFromNative - ${event.detail.name}`);

    const name = event.detail.name;
    if (name === InteropConstants.NATIVE_EVENT_POPOVERWILLSHOW_DETAILNAME) {
      const now = new Date();

      const updateSelected =
        this._popoverClosedTime &&
        differenceInMinutes(now, this._popoverClosedTime) > 30;
      const updateToday = !isSameDay(this.state.selectedDateContext.today, now);

      if (updateToday || updateSelected) {
        this.setState(state => ({
          ...state,
          selectedDateContext: {
            ...state.selectedDateContext,
            today: updateToday ? now : state.selectedDateContext.today,
            selectedDate: updateSelected
              ? now
              : state.selectedDateContext.selectedDate
          }
        }));
      }
    } else if (
      name === InteropConstants.NATIVE_EVENT_POPOVERWILLCLOSE_DETAILNAME
    ) {
      // note the time we closed
      this._popoverClosedTime = new Date();
    }
  };

  private fetchCurrentMonthAppointments = (): Promise<void> => {
    window.CalendarService.raiseEventGetAppointmentsForMonth(
      this.getDisplayDate().getFullYear(),
      this.getDisplayDate().getMonth()
    );

    return Promise.resolve();
  };

  private primeSurroundingMonths = (date: Date) => {
    const prev = addMonths(date, -1);
    const next = addMonths(date, 1);
    window.CalendarService.raiseEventGetAppointmentsForMonth(
      prev.getFullYear(),
      prev.getMonth()
    );
    window.CalendarService.raiseEventGetAppointmentsForMonth(
      next.getFullYear(),
      next.getMonth()
    );
  };
}

export default withRouter(MonthCalendar);
