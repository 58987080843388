import { Calendar, Appointment } from "./CalendarService";

/**
 * Checks if two Calendar objects are equal by checking their hash values
 *
 * @export
 * @param {ICalendar} calendarA
 * @param {ICalendar} calendarB
 * @returns {boolean}
 */
export function areCalendarsEqual(
  calendarA: Calendar,
  calendarB: Calendar
): boolean {
  return calendarA.Hash === calendarB.Hash;
}

/**
 * Checks if two Calendar arrays contain the same Calendar objects
 * in the same order.
 *
 * @export
 * @param {(ICalendar[] | undefined)} listA
 * @param {(ICalendar[] | undefined)} listB
 * @returns
 */
export function areCalendarListsEqual(
  listA: Calendar[] | undefined,
  listB: Calendar[] | undefined
) {
  // if both undefined then consider them equal
  if (!listA && !listB) {
    return true;
  }

  // if just one is undefined then not equal
  if (!listA || !listB) {
    return false;
  }

  if (listA.length !== listB.length) {
    return false;
  }

  // tslint:disable-next-line:prefer-for-of
  for (let index = 0; index < listA.length; index += 1) {
    if (!areCalendarsEqual(listA[index], listB[index])) {
      return false;
    }
  }

  return true;
}

/**
 * A sort comparitor to order appointments by start time
 *
 * @export
 * @param {Appointment} a
 * @param {Appointment} b
 * @returns
 */
export function appointmentComparer(a: Appointment, b: Appointment) {
  if (a.IsAllDay && !b.IsAllDay) {
    return -1;
  }

  if (!a.IsAllDay && b.IsAllDay) {
    return 1;
  }

  if (a.StartTime < b.StartTime) {
    return -1;
  }

  if (a.StartTime > b.StartTime) {
    return 1;
  }

  if (a.EndTime < b.EndTime) {
    return -1;
  }

  if (a.EndTime > b.EndTime) {
    return 1;
  }

  return 0;
}

/**
 * Checks if two Appointment objects are equal by checking their Hash values
 *
 * @export
 * @param {Appointment} appointmentA
 * @param {Appointment} appointmentB
 * @returns {boolean}
 */
export function areAppointemntsEqual(
  appointmentA: Appointment,
  appointmentB: Appointment
): boolean {
  return appointmentA.Hash === appointmentB.Hash;
}

/**
 * Checks if two Appointment arrays contain the same Appointment objects
 * in the same order.
 *
 * @export
 * @param {Appointment[]} listA
 * @param {Appointment[]} listB
 * @returns
 */
export function areAppointmentListsEqual(
  listA: Appointment[] | undefined,
  listB: Appointment[] | undefined
) {
  // if both undefined then consider them equal
  if (!listA && !listB) {
    return true;
  }

  // if just one is undefined then not equal
  if (!listA || !listB) {
    return false;
  }

  if (listA.length !== listB.length) {
    return false;
  }

  // tslint:disable-next-line:prefer-for-of
  for (let index = 0; index < listA.length; index += 1) {
    if (!areAppointemntsEqual(listA[index], listB[index])) {
      return false;
    }
  }

  return true;
}

/**
 * Takes in year and month and returns a string of format YYYY-MM
 *
 * @export
 * @param {number} year
 * @param {number} month
 * @returns {string}
 */
export function yearMonthToString(year: number, month: number): string {
  // TODO - input validation (month must be between 0 and 11 inclusive)

  const safeMonth = `0${month + 1}`.slice(-2);
  return `${year}-${safeMonth}`;
}

/**
 * Takes in a date and returns a string of format YYYY-MM
 *
 * @export
 * @param {Date} date
 * @returns {string}
 */
export function dateToYearMonthString(date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth();
  return yearMonthToString(year, month);
}
