import { StorageService } from "./StorageService";
import * as localforage from "localforage";

// TODO - drop localforage and kv storage - https://developers.google.com/web/updates/2019/03/kv-storage

export default class LocalForageStorageService implements StorageService {
  private constructor() {}

  public static async Build(): Promise<StorageService> {
    await localforage.setDriver([
      localforage.INDEXEDDB,
      localforage.LOCALSTORAGE
    ]);

    return new LocalForageStorageService();
  }

  public async upsert<T>(key: string, value: T): Promise<void> {
    await localforage.setItem(key, value);
  }

  public remove(key: string): Promise<void> {
    return localforage.removeItem(key);
  }

  public async get<T>(key: string): Promise<T | undefined> {
    const stored = await localforage.getItem<T>(key);
    if (stored === null) {
      return undefined;
    }
    return stored;
  }
}
