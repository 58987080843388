import * as React from "react";
import styles from "./CalendarAccountsControl.module.css";
import {
  CalendarAccount,
  CalendarAccountType
} from "../../services/calendar/CalendarService";
import CalendarAccountItem from "./CalendarAccountItem";
import empty_box from "../../assets/empty_box.svg";

interface IComponentState {
  calendarAccounts?: CalendarAccount[];
  error?: Error;
}

interface IComponentProps {}

export default class CalendarAccountsControl extends React.Component<
  IComponentProps,
  IComponentState
> {
  private _unsubscribeHandler?: () => void;

  constructor(props: IComponentProps) {
    super(props);

    this.state = {
      calendarAccounts: undefined,
      error: undefined
    };

    this._unsubscribeHandler = window.CalendarService.registerHandlerGetCalendarAccounts(
      (payload, error) => {
        this.setState(state => ({
          ...state,
          isLoading: false,
          calendarAccounts: payload && payload.calendarAccounts,
          error
        }));
      }
    );
  }

  public componentDidMount() {
    window.CalendarService.raiseEventGetCalendarAccounts();
  }

  public componentWillUnmount() {
    if (this._unsubscribeHandler) {
      this._unsubscribeHandler();
    }
  }

  public render() {
    if (
      this.state.calendarAccounts === undefined &&
      this.state.error === undefined
    ) {
      return <div className={styles["host"]}>{this.renderLoading()}</div>;
    }

    if (this.state.error) {
      return (
        <div className={styles["host"]}>
          {this.renderAddAccountButtons()}
          {this.renderError(this.state.error)}
        </div>
      );
    }

    if (this.state.calendarAccounts!.length < 1) {
      return (
        <div className={styles["host"]}>
          {this.renderAddAccountButtons()}
          {this.renderNoAccounts()}
        </div>
      );
    }

    return (
      <div className={styles["host"]}>
        {this.renderAddAccountButtons()}
        <div className={styles["account-list"]}>
          {this.state.calendarAccounts!.map((ca, index, _) => {
            return (
              <div key={`ca-${index}`}>
                <CalendarAccountItem calendarAccount={ca} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  private renderAddAccountButtons() {
    return (
      <div className={styles["add-account-bar"]}>
        <button
          className={styles["add-account-button"]}
          onClick={() =>
            window.CalendarService.addCalendarAccount(CalendarAccountType.MOCK)
          }
        >
          Add Example Calendar
        </button>
        <button
          className={styles["add-account-button"]}
          onClick={() =>
            window.CalendarService.addCalendarAccount(
              CalendarAccountType.OUTLOOK
            )
          }
        >
          Add Outlook
        </button>
      </div>
    );
  }

  private renderNoAccounts = () => {
    return (
      <div className={styles["no-accounts"]}>
        <h2>No Accounts Setup</h2>
        <img src={empty_box} style={{ height: "40vmin" }} alt="No Accounts" />
      </div>
    );
  };

  private renderLoading = () => {
    return <div>Loading...</div>;
  };

  private renderError = (error: Error) => {
    // TODO - massage the error message
    return <div>{error.message}</div>;
  };
}
