import * as React from "react";
import { Appointment } from "../../services/calendar/CalendarService";

interface IComponentState {}

interface IComponentProps {
  appointment: Appointment;
  showFade?: boolean;
}

const containerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  paddingTop: "7px",
  paddingBottom: "7px",
  paddingLeft: "21px",
  marginBottom: "9px"
};

const stackedStyle: React.CSSProperties = {
  flex: "0 0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  paddingRight: "21px"
};

const overflowTextStyle: React.CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
};

export default class AppointmentListAllDayItem extends React.PureComponent<
  IComponentProps,
  IComponentState
> {
  public render() {
    const rowStyle = {
      ...containerStyle,
      opacity: this.props.showFade ? 0.5 : 1.0,
      backgroundColor: this.props.appointment.Calendar.Color
    };

    return (
      <div style={rowStyle} onClick={this.handleClick}>
        <div style={stackedStyle}>
          <div style={overflowTextStyle}>{this.props.appointment.Title}</div>
          <div style={overflowTextStyle}>{this.props.appointment.Location}</div>
        </div>
      </div>
    );
  }

  private handleClick = (e: any) => {
    // if (getIsInMacWebview()) {
    //   (window as any).webkit.messageHandlers.scriptHandler.postMessage(
    //     `${InteropConstants.JS_MESSAGE_OPENAPPOINTMENT}${
    //       InteropConstants.MESSAGE_DELIMITER
    //     }${this.props.appointment.Id}`
    //   );
    // }
  };
}
