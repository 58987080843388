import { addDays } from "date-fns";
import {
  CalendarService,
  GetCalendarAccountsPayload,
  CalendarAccount,
  CalendarAccountType,
  GetCalendarsPayload,
  Calendar,
  GetAppointmentsPayload,
  AccountNotFoundError,
  Appointment
} from "./CalendarService";
import { CalendarProvider } from "./providers/CalendarProvider";
import MockCalendarProvider from "./providers/MockCalendarProvider";
import OutlookCalendarProvider from "./providers/OutlookCalendarProvider";
import { StorageService } from "../storage/StorageService";
import {
  yearMonthToString,
  appointmentComparer,
  areAppointmentListsEqual
} from "./CalendarUtils";

enum Constants {
  AccountListStorageKey = "MasterCalendarService-AccountsList",
  DisabledCalendarsStorageKey = "MasterCalendarService-DisabledCalendars",
  GetCalendarsPayloadCacheStorageKey = "MasterCalendarService-Calendars",
  GetAppointmentsPayloadCacheStorageKey = "MasterCalendarService-Appointments",
  AppointmentTTL = 60000, // 1 minute TTL
  CalendarTTL = 300000 // 5 minute TTL
}

function getProviderFromAccountType(
  type: CalendarAccountType
): CalendarProvider {
  switch (type) {
    case CalendarAccountType.MOCK:
      return MockCalendarProvider;
    case CalendarAccountType.OUTLOOK:
      return OutlookCalendarProvider;
    default:
      throw "not implemented";
  }
}

function buildCalendarKey(calendar: Calendar): string {
  return `${calendar.Account.Id}-${calendar.Id}`;
}

type GetCalendarsPayloadCache = { [s: string]: GetCalendarsPayload };
type GetAppointmentsPayloadCache = { [s: string]: GetAppointmentsPayload };

export default class MasterCalendarService implements CalendarService {
  private _getCalendarAccountsHandlersIndex: number = 0;
  private _getCalendarAccountsHandlers: {
    [s: string]: (
      payload: GetCalendarAccountsPayload | undefined,
      error: Error | undefined
    ) => void;
  } = Object.create(null);

  private _getCalendarsHandlersIndex: number = 0;
  private _getCalendarsHandlers: {
    [s: string]: (
      payload: GetCalendarsPayload | undefined,
      error: Error | undefined
    ) => void;
  } = Object.create(null);

  private _getAppointemntsHandlersIndex: number = 0;
  private _getAppointmentsHandlers: {
    [s: string]: (
      payload: GetAppointmentsPayload | undefined,
      error: Error | undefined
    ) => void;
  } = Object.create(null);

  private constructor(
    private storageService: StorageService,
    private accounts: CalendarAccount[],
    private disabledCalendars: Set<string>,
    private calendarPayloadsCache: GetCalendarsPayloadCache,
    private appointmentPayloadsCache: GetAppointmentsPayloadCache
  ) {
    this.raiseEventGetCalendarAccounts.bind(this);
    this.registerHandlerGetCalendarAccounts.bind(this);
    this.addCalendarAccount.bind(this);
    this.raiseEventGetCalendars.bind(this);
    this.registerHandlerGetCalendars.bind(this);
    this.getAccountFromId.bind(this);
    this.enableCalendar.bind(this);
    this.disableCalendar.bind(this);
    this.raiseEventGetAppointments.bind(this);
    this.registerHandlerGetAppointments.bind(this);
    this.getCalendarsForAccount.bind(this);
  }

  public static async Build(
    storageService: StorageService
  ): Promise<CalendarService> {
    // read accounts from storage
    const storedAccounts = await storageService.get<CalendarAccount[]>(
      Constants.AccountListStorageKey
    );
    const accounts = storedAccounts === undefined ? [] : storedAccounts;

    // read disabledCalendars from storage
    const storedDisabledCalendars = await storageService.get<Set<string>>(
      Constants.DisabledCalendarsStorageKey
    );
    const disabledCalendars =
      storedDisabledCalendars === undefined
        ? new Set<string>()
        : storedDisabledCalendars;

    // read calendar cache from storage
    const storedCalendarCache = await storageService.get<
      GetCalendarsPayloadCache
    >(Constants.GetCalendarsPayloadCacheStorageKey);
    const calendarCache =
      storedCalendarCache === undefined
        ? Object.create(null)
        : storedCalendarCache;

    // read appointment cache from storage
    const storedAppointmentCache = await storageService.get<
      GetAppointmentsPayloadCache
    >(Constants.GetAppointmentsPayloadCacheStorageKey);
    const appointmentCache =
      storedAppointmentCache === undefined
        ? Object.create(null)
        : storedAppointmentCache;

    const instance = new MasterCalendarService(
      storageService,
      accounts,
      disabledCalendars,
      calendarCache,
      appointmentCache
    );
    return instance;
  }

  private getAccountFromId(
    accountId: string
  ): Promise<CalendarAccount | undefined> {
    return Promise.resolve(this.accounts!.find(a => a.Id === accountId));
  }

  private async bustAppointmentCache(): Promise<void> {
    this.appointmentPayloadsCache = Object.create(null);

    await this.storageService.remove(
      Constants.GetCalendarsPayloadCacheStorageKey
    );

    // TODO: keep track of which events to re-raise
  }

  public raiseEventGetCalendarAccounts(): void {
    if (Object.keys(this._getCalendarAccountsHandlers).length < 1) {
      return;
    }

    new Promise<GetCalendarAccountsPayload>((resolve, reject) => {
      try {
        let payload: GetCalendarAccountsPayload = {
          calendarAccounts: this.accounts!
        };
        Object.keys(this._getCalendarAccountsHandlers).forEach(k => {
          this._getCalendarAccountsHandlers[k](payload, undefined);
        });
      } catch (error) {
        Object.keys(this._getCalendarAccountsHandlers).forEach(k => {
          this._getCalendarAccountsHandlers[k](undefined, error);
        });
      }
    });
  }

  public registerHandlerGetCalendarAccounts(
    handler: (
      payload: GetCalendarAccountsPayload | undefined,
      error: Error | undefined
    ) => void
  ): () => void {
    const index = this._getCalendarAccountsHandlersIndex++;
    this._getCalendarAccountsHandlers[index] = handler;

    // return an unsubscribe func so caller can cleanup after themselves
    return () => {
      delete this._getCalendarAccountsHandlers[index];
    };
  }

  public async addCalendarAccount(
    accountType: CalendarAccountType
  ): Promise<void> {
    const provider = getProviderFromAccountType(accountType);
    const account = await provider.login();
    this.accounts!.push(account);

    await this.storageService.upsert(
      Constants.AccountListStorageKey,
      this.accounts
    );

    await this.bustAppointmentCache();
    this.raiseEventGetCalendarAccounts();
  }

  public async removeCalendarAccount(accountId: string): Promise<void> {
    const account = this.accounts!.find(a => a.Id === accountId);
    if (!account) {
      // TODO - better error object
      throw `account with id ${accountId} not found`;
    }

    const provider = getProviderFromAccountType(account.Type);
    await provider.logout(accountId);

    this.accounts = this.accounts.filter(a => a.Id !== accountId);

    await this.storageService.upsert(
      Constants.AccountListStorageKey,
      this.accounts
    );

    await this.bustAppointmentCache();
    this.raiseEventGetCalendarAccounts();
  }

  private async getCalendarsForAccount(
    accountId: string
  ): Promise<GetCalendarsPayload> {
    const account = this.accounts.find(a => a.Id === accountId);
    if (!account) {
      // TODO - better error object
      throw `account with id ${accountId} not found`;
    }

    const cachedPayload = this.calendarPayloadsCache![accountId];
    const now = new Date();

    if (
      cachedPayload &&
      cachedPayload.syncDate &&
      now.getTime() - cachedPayload.syncDate < Constants.CalendarTTL
    ) {
      // check the source of truth for disabled calendars in case it has changed
      // since we last updated our cache
      cachedPayload.calendars.forEach(
        c => (c.IsDisabled = this.disabledCalendars!.has(buildCalendarKey(c)))
      );
      return cachedPayload;
    }

    const provider = getProviderFromAccountType(account.Type);
    const calendars = await provider.getCalendars(account);

    // make sure we properly set the IsDisabled prop since that's a local
    // property (we don't read it from or write it to the server)
    calendars.forEach(
      c => (c.IsDisabled = this.disabledCalendars!.has(buildCalendarKey(c)))
    );

    const payload = <GetCalendarsPayload>{ calendars, syncDate: now.getTime() };

    // save to db
    this.calendarPayloadsCache![accountId] = payload;
    await this.storageService.upsert(
      Constants.GetCalendarsPayloadCacheStorageKey,
      this.calendarPayloadsCache
    );

    return payload;
  }

  public raiseEventGetCalendars(accountId: string): void {
    if (Object.keys(this._getCalendarsHandlers).length < 1) {
      return;
    }

    new Promise<GetCalendarsPayload>(async (_resolve, _reject) => {
      try {
        const account = this.accounts!.find(a => a.Id === accountId);
        if (!account) {
          Object.keys(this._getCalendarsHandlers).forEach(k => {
            this._getCalendarsHandlers[k](
              undefined,
              new AccountNotFoundError(`account with id ${accountId} not found`)
            );
          });
          return;
        }

        const payload = await this.getCalendarsForAccount(accountId);

        Object.keys(this._getCalendarsHandlers).forEach(k => {
          this._getCalendarsHandlers[k](payload, undefined);
        });
      } catch (error) {
        Object.keys(this._getCalendarsHandlers).forEach(k => {
          this._getCalendarsHandlers[k](undefined, error);
        });
      }
    });
  }

  public registerHandlerGetCalendars(
    handler: (
      payload: GetCalendarsPayload | undefined,
      error: Error | undefined
    ) => void
  ): () => void {
    const index = this._getCalendarsHandlersIndex++;
    this._getCalendarsHandlers[index] = handler;

    // return an unsubscribe func so caller can cleanup after themselves
    return () => {
      delete this._getCalendarsHandlers[index];
    };
  }

  public async enableCalendar(calendar: Calendar): Promise<void> {
    this.disabledCalendars!.delete(buildCalendarKey(calendar));

    await this.storageService.upsert(
      Constants.DisabledCalendarsStorageKey,
      this.disabledCalendars
    );

    this.raiseEventGetCalendars(calendar.Account.Id);
  }

  public async disableCalendar(calendar: Calendar): Promise<void> {
    this.disabledCalendars!.add(buildCalendarKey(calendar));

    await this.storageService.upsert(
      Constants.DisabledCalendarsStorageKey,
      this.disabledCalendars
    );

    this.raiseEventGetCalendars(calendar.Account.Id);
  }

  public raiseEventGetAppointments(start: Date, end: Date): void {
    if (Object.keys(this._getAppointmentsHandlers).length < 1) {
      return;
    }

    new Promise<GetAppointmentsPayload>(async (_resolve, _reject) => {
      try {
        // TODO - cache this

        this.accounts!.forEach(async account => {
          const provider = getProviderFromAccountType(account.Type);
          const appointments = await provider.getAppointments(
            account,
            start,
            end
          );
          Object.keys(this._getAppointmentsHandlers).forEach(k => {
            this._getAppointmentsHandlers[k](
              {
                appointments: appointments,
                syncDate: new Date().getTime()
              },
              undefined
            );
          });
        });
      } catch (error) {
        Object.keys(this._getAppointmentsHandlers).forEach(k => {
          this._getAppointmentsHandlers[k](undefined, error);
        });
      }
    });
  }

  public raiseEventGetAppointmentsForMonth(year: number, month: number): void {
    if (Object.keys(this._getAppointmentsHandlers).length < 1) {
      return;
    }

    new Promise<GetAppointmentsPayload>(async (_resolve, _reject) => {
      try {
        if (this.accounts!.length < 1) {
          Object.keys(this._getAppointmentsHandlers).forEach(k => {
            this._getAppointmentsHandlers[k](
              <GetAppointmentsPayload>{ appointments: [], month, year },
              undefined
            );
          });
          return;
        }

        let date = new Date(year, month, 1);
        // get appointments starting 7 days before the first of the month
        const start = addDays(date, -7);
        // get appointments stopping about 7 days after the end of the month (some
        // months aren't fully 31 days but that's fine)
        const end = addDays(date, 37);

        // first attempt to emit from cached value
        const cached = this.appointmentPayloadsCache![
          yearMonthToString(year, month)
        ];
        if (cached) {
          Object.keys(this._getAppointmentsHandlers).forEach(k => {
            this._getAppointmentsHandlers[k](cached, undefined);
          });
        }

        // now loop through and hit network if older than TTL
        if (
          !cached ||
          !cached.syncDate ||
          new Date().getTime() - cached.syncDate > Constants.AppointmentTTL
        ) {
          let allAppointments: Appointment[] = [];
          const appointmentArrays = await Promise.all(
            this.accounts!.map(a =>
              getProviderFromAccountType(a.Type).getAppointments(a, start, end)
            )
          );

          appointmentArrays.forEach(aa => {
            allAppointments = allAppointments.concat(aa);
          });

          allAppointments.sort(appointmentComparer);

          const payload = <GetAppointmentsPayload>{
            appointments: allAppointments,
            syncDate: new Date().getTime(),
            month: month,
            year: year
          };

          if (
            !cached ||
            !areAppointmentListsEqual(cached.appointments, payload.appointments)
          ) {
            Object.keys(this._getAppointmentsHandlers).forEach(k => {
              this._getAppointmentsHandlers[k](payload, undefined);
            });
          }

          this.appointmentPayloadsCache[
            yearMonthToString(year, month)
          ] = payload;

          // save to db
          await this.storageService.upsert(
            Constants.GetAppointmentsPayloadCacheStorageKey,
            this.appointmentPayloadsCache
          );
        }
      } catch (error) {
        Object.keys(this._getAppointmentsHandlers).forEach(k => {
          this._getAppointmentsHandlers[k](undefined, error);
        });
      }
    });
  }

  public registerHandlerGetAppointments(
    handler: (
      payload: GetAppointmentsPayload | undefined,
      error: Error | undefined
    ) => void
  ): () => void {
    const index = this._getAppointemntsHandlersIndex++;
    this._getAppointmentsHandlers[index] = handler;

    // return an unsubscribe func so caller can cleanup after themselves
    return () => {
      delete this._getAppointmentsHandlers[index];
    };
  }
}
