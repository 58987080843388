import { CalendarProvider } from "./CalendarProvider";
import {
  CalendarAccountAuthStatus,
  Calendar,
  Appointment,
  CalendarAccount,
  CalendarAccountType
} from "../CalendarService";
import { addDays, isSameDay, setHours, addMinutes, addHours } from "date-fns";
import { appointmentComparer } from "../CalendarUtils";
import { guid } from "../../../utils";

let _authenticated = false;

const getCalendar1 = (account: CalendarAccount): Calendar => {
  const id = "123";

  return {
    Account: account,
    Title: "Team Calendar",
    Id: id,
    Hash: `${id}`,
    Color: "#3255c2",
    IsDisabled: false
  };
};

const getCalendar2 = (account: CalendarAccount): Calendar => {
  const id = "456";

  return {
    Account: account,
    Title: "Calendar",
    Id: id,
    Hash: `${id}`,
    Color: "#64189F",
    IsDisabled: false
  };
};

function login(): Promise<CalendarAccount> {
  return Promise.resolve(<CalendarAccount>{
    Id: guid(),
    Type: CalendarAccountType.MOCK,
    AccountName: "Demo Account",
    AuthStatus: CalendarAccountAuthStatus.Authenticated
  });
}

function logout(accountId: string): Promise<void> {
  return Promise.resolve();
}

function getAuthStatus(): Promise<CalendarAccountAuthStatus> {
  return Promise.resolve(
    _authenticated
      ? CalendarAccountAuthStatus.Authenticated
      : CalendarAccountAuthStatus.NeedsAuthentication
  );
}

function getCalendars(account: CalendarAccount): Promise<Calendar[]> {
  let calendars: Calendar[] = [];
  calendars.push(getCalendar1(account));
  calendars.push(getCalendar2(account));

  return Promise.resolve(calendars);
}

function getAppointments(
  account: CalendarAccount,
  start: Date,
  end: Date
): Promise<Appointment[]> {
  const appts: Appointment[] = [];

  const titles = [
    "Tax Appointment",
    "Karen's Birthday",
    "Meeting with the Bob's",
    "Soccer practice",
    "Review FY19 Numbers",
    "Yet Another Birthday Party",
    "Jim's Birthday",
    "Lunch with Kim",
    "Library Books due back",
    "Take van to shop",
    "Replace tires on truck",
    "Jack's birthday",
    "Aaron's birthday",
    "Some other holiday",
    "Pizza with those people",
    "Another made up appointment"
  ];

  const locations = [
    "",
    "123 Seesaw Street",
    "Conference Room A",
    "The big room with the table",
    "9823 Williams Road",
    "Conference Room 12",
    "",
    "",
    ""
  ];

  const numberOfAppointmentsPerDay = [
    2,
    1,
    0,
    3,
    2,
    1,
    1,
    1,
    0,
    0,
    3,
    0,
    2,
    3,
    5,
    4,
    1,
    1,
    0,
    3,
    4,
    1,
    0,
    1,
    2,
    2
  ];

  const cal1 = getCalendar1(account);
  const cal2 = getCalendar2(account);
  const cals = [cal1, cal2];

  let dayCounter = 0;
  let titleLocationCounter = 0;
  let dayWalker = start;
  const dayAfterEndDate = addDays(end, 1);
  while (!isSameDay(dayWalker, dayAfterEndDate)) {
    const numApptsForDay =
      numberOfAppointmentsPerDay[
        dayCounter % numberOfAppointmentsPerDay.length
      ];
    for (let index = 0; index < numApptsForDay; index += 1) {
      const isAllDay = titleLocationCounter % 4 === 0;
      const hourOffset = titleLocationCounter % 8;
      let start = setHours(dayWalker, 8 + hourOffset);
      let end = addMinutes(start, titleLocationCounter % 1 === 1 ? 30 : 60);

      if (isAllDay) {
        start = setHours(dayWalker, 0);
        end = addHours(start, 24);
      }
      const cal = cals[titleLocationCounter % 2];
      if (!cal.IsDisabled) {
        appts.push({
          Calendar: cal,
          StartTime: start.getTime(),
          EndTime: end.getTime(),
          IsAllDay: isAllDay,
          Location: locations[titleLocationCounter % locations.length],
          Title: titles[titleLocationCounter % titles.length],
          Id: titleLocationCounter.toString(),
          Hash: titleLocationCounter.toString()
        });
      }
      titleLocationCounter += 1;
    }
    dayWalker = addDays(dayWalker, 1);
    dayCounter += 1;
  }

  appts.sort(appointmentComparer);
  return Promise.resolve(appts);
}

export default <CalendarProvider>{
  login,
  logout,
  getAuthStatus,
  getCalendars,
  getAppointments
};
