export interface CalendarService {
  /**
   * Tells the CalendarService to raise an event requesting all
   * CalendarAccount's.  Make sure you have registered a handler
   * before calling this.
   *
   * @memberof CalendarService
   */
  raiseEventGetCalendarAccounts(): void;

  /**
   * Registers an event handler whenever the service is ready to return
   * a GetCalendarAccountsPayload
   *
   * @param {(payload: GetCalendarAccountsPayload | undefined, error: Error | undefined) => void} handler
   * The handler function to run when a payload is returned from the service.
   * @returns {() => void}
   * An event handler unsubscribe function
   * @memberof CalendarService
   */
  registerHandlerGetCalendarAccounts(
    handler: (
      payload: GetCalendarAccountsPayload | undefined,
      error: Error | undefined
    ) => void
  ): () => void;

  addCalendarAccount(accountType: CalendarAccountType): Promise<void>;

  removeCalendarAccount(accountId: string): Promise<void>;

  raiseEventGetCalendars(accountId: string): void;
  registerHandlerGetCalendars(
    handler: (
      payload: GetCalendarsPayload | undefined,
      error: Error | undefined
    ) => void
  ): () => void;

  enableCalendar(calendar: Calendar): Promise<void>;
  disableCalendar(calendar: Calendar): Promise<void>;

  raiseEventGetAppointments(start: Date, end: Date): void;
  raiseEventGetAppointmentsForMonth(year: number, month: number): void;
  registerHandlerGetAppointments(
    handler: (
      payload: GetAppointmentsPayload | undefined,
      error: Error | undefined
    ) => void
  ): () => void;
}

export interface GetCalendarAccountsPayload {
  calendarAccounts: CalendarAccount[];
}

export interface GetCalendarsPayload {
  calendars: Calendar[];

  /**
   * Unix time in milliseconds
   *
   * @type {number}
   * @memberof GetCalendarsPayload
   */
  syncDate?: number;
}

export interface GetAppointmentsPayload {
  appointments: Appointment[];

  /**
   * Unix time in milliseconds
   *
   * @type {number}
   * @memberof GetAppointmentsPayload
   */
  syncDate?: number;

  /**
   * If the payload is for a certain year/month combo then this will
   * contain the month, otherwise it's undefined
   *
   * @type {(number | undefined)}
   * @memberof GetAppointmentsPayload
   */
  month?: number;

  /**
   * If the payload is for a certain year/month combo then this will
   * contain the year, otherwise it's undefined
   *
   * @type {(number | undefined)}
   * @memberof GetAppointmentsPayload
   */
  year?: number;
}

export interface CalendarAccount {
  Id: string;
  AuthStatus: CalendarAccountAuthStatus;
  AccountName: string;
  Type: CalendarAccountType;
}

export enum CalendarAccountAuthStatus {
  Unknown = 0, // TODO: we might not need unknown
  NeedsAuthentication = 1,
  Authenticated = 2
}

export enum CalendarAccountType {
  OUTLOOK = "outlook",
  GOOGLE = "google",
  SYSTEM = "system",
  MOCK = "mock"
}

export interface Calendar {
  Id: string;
  Hash: string;
  Account: CalendarAccount;
  Title: string;

  /**
   * Hex string that represents a color (includes the hash character).
   * Example "#32fe32".
   *
   * @type {string}
   * @memberof Calendar
   */
  Color: string;
  IsDisabled: boolean;
}

export interface Appointment {
  Id: string;
  Hash: string;
  Calendar: Calendar;
  Title: string;
  Location: string;
  IsAllDay: boolean;

  /**
   * Unix time in milliseconds
   *
   * @type {number}
   * @memberof Appointment
   */
  StartTime: number;

  /**
   * Unix time in milliseconds
   *
   * @type {number}
   * @memberof Appointment
   */
  EndTime: number; // unix time in milliseconds
}

export class NotAuthenticatedError extends Error {
  constructor(message?: string) {
    super(message);
    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = NotAuthenticatedError.name;
  }
}

export class AccountNotFoundError extends Error {
  constructor(message?: string) {
    super(message);
    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = AccountNotFoundError.name;
  }
}
