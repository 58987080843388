import { Client } from "@microsoft/microsoft-graph-client";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { UserAgentApplication } from "msal";
import { CalendarProvider } from "./CalendarProvider";
import {
  CalendarAccountAuthStatus,
  Calendar,
  Appointment,
  CalendarAccount,
  CalendarAccountType,
  NotAuthenticatedError
} from "../CalendarService";
import { appointmentComparer } from "../CalendarUtils";

const authority = "https://login.microsoftonline.com/common/";
const clientId = "e59598a1-891c-43c0-8e52-8bf6fc848942";
const graphScopes = ["user.read", "calendars.read", "calendars.read.shared"];

// An Optional options for initializing the MSAL @see https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/MSAL-basics#configuration-options
// look at this for auth stuff - https://github.com/microsoftgraph/msgraph-training-reactspa

const userAgentApplication = new UserAgentApplication(clientId, null, token => {
  // TODO: figure out if I need to do anything here
  console.log("got the token back");
});

let _client: Client | null = null;

async function login(): Promise<CalendarAccount> {
  await userAgentApplication.loginPopup(graphScopes);

  return await getCalendarAccountInfo();
}

async function logout(accountId: string): Promise<void> {
  return Promise.resolve();

  // TODO - actually logging out redirects user to log out of their
  // MS account in the browser, which seems like overkill
  // return new Promise((resolve, _reject) => {
  //   userAgentApplication.logout();
  //   resolve();
  // });
}

async function ensureLoggedIn(): Promise<boolean> {
  if (!_client) {
    try {
      const token = await userAgentApplication.acquireTokenSilent(
        graphScopes,
        authority
      );
      if (token) {
        _client = Client.init({
          authProvider: done => {
            done(null, token);
          }
        });
      } else {
        _client = null;
        return false;
      }
    } catch (err) {
      // TODO: inspect error and see if we can do better
      if (err) {
        console.error(err);
      }

      _client = null;
      return false;
    }
  }

  return true;
}

async function getAuthStatus(): Promise<CalendarAccountAuthStatus> {
  const loggedIn = await ensureLoggedIn();

  return loggedIn
    ? CalendarAccountAuthStatus.Authenticated
    : CalendarAccountAuthStatus.NeedsAuthentication;
}

async function getCalendarAccountInfo(): Promise<CalendarAccount> {
  if (!(await ensureLoggedIn())) {
    throw new NotAuthenticatedError();
  }

  // TODO - error handling
  let userData: MicrosoftGraph.User = await _client!.api("/me").get();
  return <CalendarAccount>{
    AuthStatus: CalendarAccountAuthStatus.Authenticated,
    AccountName: userData.mail,
    Id: userData.id,
    Type: CalendarAccountType.OUTLOOK
  };
}

function colorToHex(color?: MicrosoftGraph.CalendarColor): string {
  switch (color) {
    case "lightBlue":
      return "#4477d0";
    case "lightGreen":
      return "#46791f";
    case "lightOrange":
      return "#be4b17";
    case "lightGray":
      return "#666666";
    case "lightYellow":
      return "#b2a925";
    case "lightTeal":
      return "#4b827d";
    case "lightPink":
      return "#c3358a";
    case "lightBrown":
      return "#8c5923";
    case "lightRed":
      return "#b23225";
    default:
      return "#3255c2";
  }
}

async function getCalendars(account: CalendarAccount): Promise<Calendar[]> {
  if (!(await ensureLoggedIn())) {
    throw new NotAuthenticatedError();
  }

  // TODO - error handling
  // TODO - handle @next link
  const response = await _client!.api("/me/calendars").get();

  const msCalendars = response.value as MicrosoftGraph.Calendar[];

  const calendars = msCalendars.map(
    c =>
      <Calendar>{
        Account: account,
        Id: c.id,
        Color: colorToHex(c.color),
        Hash: c.changeKey,
        IsDisabled: false,
        Title: c.name
      }
  );

  return calendars;
}

async function getEventsFromLink(
  link: string
): Promise<MicrosoftGraph.Event[]> {
  var response = await _client!.api(link).get();

  let msAppointments: MicrosoftGraph.Event[] = response.value;

  const nextLink = response["@odata.nextLink"];
  if (nextLink) {
    const nextEvents = await getEventsFromLink(nextLink);
    msAppointments = msAppointments.concat(nextEvents);
  }

  return msAppointments;
}

async function getAppointmentsForCalendar(
  calendar: Calendar,
  start: Date,
  end: Date
): Promise<Appointment[]> {
  if (!(await ensureLoggedIn())) {
    throw new NotAuthenticatedError();
  }

  const link = `me/calendars/${
    calendar.Id
  }/calendarview?startdatetime=${start.toISOString()}&enddatetime=${end.toISOString()}`;

  const msAppointments = await getEventsFromLink(link);

  const appointments: Appointment[] = msAppointments.map(
    a =>
      <Appointment>{
        Calendar: calendar,
        Id: a.id,
        Title: a.subject,
        StartTime: new Date(
          `${a.start!.dateTime!}${
            a.start!.timeZone!.toLowerCase() === "utc" && !a.isAllDay ? "z" : ""
          }`
        ).getTime(),
        EndTime: new Date(
          `${a.end!.dateTime!}${
            a.end!.timeZone!.toLowerCase() === "utc" && !a.isAllDay ? "z" : ""
          }`
        ).getTime(),
        Hash: a.changeKey,
        IsAllDay: a.isAllDay,
        Location: a.location && a.location.displayName // TODO: can probably do better with location
      }
  );

  appointments.sort(appointmentComparer);
  return appointments;
}

async function getAppointments(
  account: CalendarAccount,
  start: Date,
  end: Date
): Promise<Appointment[]> {
  if (end < start) {
    throw "end Date is before start Date";
  }

  if (!(await ensureLoggedIn())) {
    throw new NotAuthenticatedError();
  }

  const cals = await getCalendars(account);

  const appointmentsForAllCalendars = await Promise.all(
    cals.map(c => getAppointmentsForCalendar(c, start, end))
  );

  let appointments: Appointment[] = [];
  appointmentsForAllCalendars.forEach(afc => {
    appointments = appointments.concat(afc);
  });

  return appointments;
}

export default <CalendarProvider>{
  login,
  logout,
  getAuthStatus,
  getCalendars,
  getAppointments
};

// endpoint for getting week's worth of appts - me/calendarview?startdatetime=2019-03-30T16:25:49.921Z&enddatetime=2019-04-06T16:25:49.921Z

// sample app code from https://docs.microsoft.com/en-us/azure/active-directory/develop/quickstart-v2-javascript
