import AppBar from "@material-ui/core/AppBar/AppBar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Switch from "@material-ui/core/Switch";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Typography from "@material-ui/core/Typography/Typography";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import { getIsInMacWebview, InteropConstants } from "../utils";
import CalendarAccountsControl from "./CalendarAccounts/CalendarAccountsControl";

const settingsContainerStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "stretch"
};

const topContainerStyle: React.CSSProperties = {
  backgroundColor: "red"
};

const bodyContainerStyle: React.CSSProperties = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingLeft: "10px",
  paddingTop: "10px"
};

interface IComponentState {
  useMacKeyboardShortcut?: boolean;
  launchAtLogin?: boolean;
}

interface IComponentProps {
  onClose: () => void;
}

export default class SettingsPage extends React.PureComponent<
  IComponentProps,
  IComponentState
> {
  constructor(props: IComponentProps) {
    super(props);

    this.state = {
      useMacKeyboardShortcut: true,
      launchAtLogin: false
    };

    window.addEventListener(
      InteropConstants.NATIVE_EVENT_EVENTNAME,
      this.handleNativeEvent
    );

    if (getIsInMacWebview()) {
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        "getPopoverDisableKeyboardShortcutSetting"
      );
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        "getLaunchAtLoginSetting"
      );
    }
  }

  public async componentDidMount() {
    // const calendars = await getCalendars();
    // this.setState(state => ({ ...state, calendars }));
  }

  public render() {
    const inWebView = getIsInMacWebview();
    return (
      <div style={settingsContainerStyle}>
        <div style={topContainerStyle}>
          <AppBar style={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.props.onClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="title" color="inherit">
                Settings
              </Typography>
            </Toolbar>
          </AppBar>
        </div>
        <div style={bodyContainerStyle}>
          <CalendarAccountsControl />
          <FormGroup>
            {inWebView && (
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.useMacKeyboardShortcut}
                    onChange={this.handleMacKeyboardShortcutSettingChanged}
                    value="useMacKeyboardShortcut"
                    color="primary"
                  />
                }
                label="Enable Keyboard Shortcut Ctrl+Option+Cmd+c"
              />
            )}
            {inWebView && (
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.launchAtLogin}
                    onChange={this.handleLaunchAtLoginSettingChanged}
                    value="launchAtLogin"
                    color="primary"
                  />
                }
                label="Launch Clean Calendar on login"
              />
            )}
          </FormGroup>
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    window.removeEventListener(
      InteropConstants.NATIVE_EVENT_EVENTNAME,
      this.handleNativeEvent
    );
  }

  private handleNativeEvent = event => {
    const name = event.detail.name;

    if (name === "popoverDisableKeyboardShortcutSetting") {
      this.setState(state => ({
        ...state,
        useMacKeyboardShortcut: event.detail.returnString !== "true"
      }));
    } else if (name === "launchAtLoginSetting") {
      this.setState(state => ({
        ...state,
        launchAtLogin: event.detail.returnString === "true"
      }));
    }
  };

  private handleMacKeyboardShortcutSettingChanged = event => {
    if (event.target.checked) {
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        "setPopoverDisableKeyboardShortcutSetting:false"
      );
    } else {
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        "setPopoverDisableKeyboardShortcutSetting:true"
      );
    }
  };

  private handleLaunchAtLoginSettingChanged = event => {
    if (event.target.checked) {
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        "setLaunchAtLoginSetting:true"
      );
    } else {
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        "setLaunchAtLoginSetting:false"
      );
    }
  };
}
