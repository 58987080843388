import MonthCalendar from "./components/MonthCalendar";
import SupportPage from "./components/SupportPage";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "typeface-roboto";
import { CalendarService } from "./services/calendar/CalendarService";
import MasterCalendarService from "./services/calendar/MasterCalendarService";
import { StorageService } from "./services/storage/StorageService";
import LocalForageStorageService from "./services/storage/LocalForageStorageService";
// import registerServiceWorker from "./registerServiceWorker";

declare global {
  interface Window {
    CalendarService: CalendarService;
    StorageService: StorageService;
  }
}

if (process.env.NODE_ENV !== "production") {
  // tslint:disable-next-line:no-var-requires
  const { whyDidYouUpdate } = require("why-did-you-update");
  whyDidYouUpdate(React);
}

async function registerGlobalServices() {
  window.StorageService = await LocalForageStorageService.Build();
  window.CalendarService = await MasterCalendarService.Build(
    window.StorageService
  );
}

registerGlobalServices().then(_ => {
  ReactDOM.render(
    <Router>
      <div style={{ height: "100%" }}>
        <Route exact path="/" component={MonthCalendar} />
        <Route exact path="/support" component={SupportPage} />
      </div>
    </Router>,
    document.getElementById("root")
  );
});

// registerServiceWorker();
